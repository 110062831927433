@import url(https://fonts.googleapis.com/css2?family=Archivo+Black&family=Lato:ital,wght@0,300;0,400;0,700;1,900&display=swap);
.loading {
  height: 100%;
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
}

.loading svg {
  width: inherit;
  height: inherit;
}

div.avatar-modal > div:first-child {
  display: flex;
  align-items: center;
  justify-content: center;
}

div.avatar-modal > div > div {
  overflow: auto !important;
}

div.user-avatar {
  position: relative;
}

div.user-avatar .avatar-hover {
  display: none;
  cursor: pointer;
}

div.user-avatar:hover .avatar-hover {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: #24242486;
}

div.user-avatar .avatar-hover > .hover-icon {
  display: none;
}

div.user-avatar:hover .avatar-hover > .hover-icon {
  display: block;
  width: 30%;
  height: 30%;
  max-width: 40px;
  max-height: 40px;
}

div.user-avatar:hover .avatar-hover > span {
  color: white;
  text-transform: uppercase;
}

.avatar-badge {
  position: absolute;
  cursor: pointer;
  bottom: 0px;
  right: 0px;
  z-index: 1;
}

.avatar-badge.photo-badge {
  font-size: 48px;
  padding: 4px;
  background: #00bcd4;
  border-radius: 50px;
  border: 3px solid white;
  color: white;
}

.avatar-badge.star-badge {
  font-size: 24px;
  color: #f7ac02;
  right: -2px;
  bottom: -2px;
}

.app-subheader > .row {
    height: 100%;
}

.app-header,
.app-subheader {
    padding: 10px 50px;
}

.app-header {
    bottom: auto;
    background: black;
    height: auto;
    /* padding-bottom: 20px !important; */
}

.subnav {
    background: rgb(215, 225, 235);
}

.app-subheader {
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    bottom: auto;
    background: #ffae8d;
    height: 52px;
}

.alert-banner-container {
    position: absolute;
    top: 112px;
    left: 0;
    right: 0;
    background: #f80505e8;
    font-size: 14px;
    padding: 4px 12px;
    text-align: center;
}

.alert-banner-container .link {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

.d-info {
    color: #fff;
    font-size: 14px;
    letter-spacing: 1.4px;
    margin-top: 20px;
    overflow: visible;
}

.d-info > p {
    overflow: visible;
    white-space: nowrap;
}

.d-logo {
    text-align: center;
    cursor: pointer;
    flex-grow: 0;
}

.nav-links {
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
    align-items: center;
}

.username-wrapper {
    display: flex;
    align-items: center !important;
    justify-content: flex-end;
}

.nav-item {
    font-size: 15px;
    color: #000;
    background: transparent;
    box-sizing: border-box;
    height: 3rem;
    flex-grow: 1;
    transition: all .08s linear;
    font-weight: 300;
    border: none;
    display: grid;
    justify-content: center;
    align-items: center;
    padding-bottom: 3px;
    border-bottom: 3px solid transparent;
}

.nav-item.active {
    text-shadow: 1px 0 rgba(0, 0, 0, 0.59), -1px 0 rgba(0, 0, 0, 0.59), 0 1px rgba(0, 0, 0, 0.59), 0 -1px rgba(0, 0, 0, 0.59), 0 0 0;
}

.nav-item:hover {
    border-bottom-color: black;
}


.nav-item.active:hover {
    /*border: 3px solid black;*/
}

.d-usermenu {
    /* margin-top: 9px; */
    text-align: right;
    float: right;
    display: flex;
    align-items: center;
    justify-content: center;
}

.name--user {
    display: inline-block;
    color: #fff;
    vertical-align: top;
    font-weight: bold;
    margin: 0px 0px 0px 8px;
    letter-spacing: 1.6px;
    cursor: pointer;
    white-space: break-spaces;
    word-break: break-word;
    text-align: center;
}

.name--user .arrow {
    font-size: 26px;
    color: #46c9d4;
}

.d-sub-left {
    text-transform: uppercase;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.d-sub-left .wrapper {
    text-transform: uppercase;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.name--church {
    color: #fff;
    font-size: 17px;
    letter-spacing: 2.5px;
    vertical-align: middle;
    margin: 3px 3px 0;
}

.notification {
    position: relative;
}

.notification .noti-count-icon-text {
    position: absolute;
    right: 1px;
    top: 5px;
    border-radius: 4em;
    text-align: center;
    background-color: #46c9d4;
    color: #fff;
    font-size: 11px;
    line-height: 13px;
    width: 13px;
    height: 13px;
}

.d-sub-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.d-sub-right .sub-menu {
    letter-spacing: 1.6px;
    color: #fff;
    font-size: 18px;
    display: inline-block;
}

.d-sub-right .sub-menu svg {
    font-size: 27px;
}

.profile-modal,
.menu-modal {
    text-align: center;
    font-family: 'Source Sans Pro', sans-serif;
}

.profile-modal .user--name {
    margin-top: 82px;
    font-size: 26px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    margin-bottom: 0;
}

.profile-modal .button {
    width: 186px;
    height: 58px;
    margin: 12px;
}

.menu-modal {
    width: 455px;
}

.menu-modal ul {
    padding: 0;
    margin-bottom: 0;
}

.menu-modal li {
    list-style: none;
    padding: 22px 32px;
    font-size: 28px;
    border-bottom: 1px solid #d8d8d8;
    text-align: left;
    cursor: pointer;
}

.menu-modal li > a {
    width: 100%;
    display: flex;
}

.menu-modal li .subp-img {
    width: 40px;
}

.menu-modal li span {
    margin-left: 18px;
    color: black;
    width: 100%;
}

#lg-title {
    display: inherit;
    font-weight: bold;
    margin-left: 10px;
}

@media screen and (max-width: 824px) {
    .app-header,
    .app-subheader {
        padding: 10px 10px;
    }
}

@media screen and (max-width: 776px) {


    .d-info {
        display: none;
    }

    .d-logo {
        text-align: left;
    }
}

@media screen and (max-width: 768px) {
    #lg-avatar {
        display: none;
    }

    #sm-avatar {
        display: inherit;
    }

}

@media screen and (min-width: 768px) {
    #sm-avatar {
        display: none;
    }

    #lg-avatar {
        display: inherit;
    }
}


@media screen and (max-width: 493px) {
    .menu-modal,
    .profile-modal {
        width: 353px;
    }

    .menu-modal li a {
        font-size: 26px;
    }
}

@media screen and (max-width: 536px) {

    /*.app-header .row {*/
    /*    flex-direction: column;*/
    /*    align-items: center;*/
    /*}*/
    /*.app-header .row .col:nth-child(3) {*/
    /*    display: flex;*/
    /*    justify-content: center;*/
    /*}*/
    .nav-links {
        /* margin-top: 1.2rem; */
        flex-direction: column;
        align-items: center;
        grid-gap: 0;
        gap: 0;
    }

    .nav-links button {
        width: 12.5%;
    }

    .d-logo {
        display: flex;
        justify-content: center;
    }
}

@media screen and (max-width: 436px) {
    .name--user span {
        display: none;
    }

    .d-sub-left .name--church {
        font-size: 14px;
        line-height: 26px;
    }
}

@media screen and (max-width: 370px) {
    .d-sub-left .name--church {
        letter-spacing: 0;
    }

    .menu-modal,
    .profile-modal {
        width: 286px;
    }

    .menu-modal li a {
        font-size: 21px;
        line-height: 42px;
    }
}

@media screen and (min-width: 644px) and (max-width: 718px) {

    .button.small {
        width: -webkit-max-content;
        width: max-content;
        padding: 0;
    }
}

@media screen and (min-width: 718px) {
    .nav-links {
        margin-left: 15px;
    }
}

@media screen and (min-width: 470px) {
    #sm-title {
        display: none;
    }
}

@media screen and (max-width: 470px) {
    #sm-title {
        flex-grow: 1;
        max-width: 100%;
    }
}
.place-autocomplete-dropdown-container {
  position: absolute;
  background: white;
  width: 100%;
  border: 2px solid rgba(0, 0, 0, 0.15);
  z-index: 1;
}

.place-autocomplete-dropdown-container > div.suggestion-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding: 4px 12px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  background-color: rgba(0, 0, 0, 0.02);
}

.place-autocomplete-dropdown-container > div.suggestion-item:last-child {
  border-bottom: none;
}

.place-autocomplete-dropdown-container > div.suggestion-item.active {
  background-color: rgba(0, 0, 0, 0.15);
}

.pagination .page-link {
  background-color: transparent;
  padding: 0 8px;
  border: none;
  line-height: 40px;
  color: #ff9871;
  font-size: 18px;
  border-radius: 30px !important;
}

.pagination .page-link:focus,
.pagination .page-link:hover {
  color: #000 !important;
  font-weight: bold;
}

.pagination .page-link img {
  width: 12px;
}

.pagination .page-link {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination .page-link.active {
  border: 2px solid #e2e2e2;
}

.table-count {
    font-weight: bold;
    padding: 0.5em 1em;
    display: block;
    margin: 0 0 -2.3em;
}

.table-count + div.table.card {
    margin-top: 34px;
}

div.table.card .table-row > .table-cell > div {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;
    max-width: 100%;
    word-wrap: anywhere;
}

div.table.card .table-row > .table-cell > div.align-left {
    justify-content: flex-start;
    margin-left: 12px !important;
}

div.table.card .table-header.table-row > .table-cell {
    text-transform: uppercase;
    font-weight: bold;
}

.table-pagination {
    position: relative;
}

.table-pagination .table-show {
    position: absolute;
    top: 6px;
    right: 8px;
    font-size: 18px;
    color: #ff9871;
    display: flex;
}

.table-pagination .table-show {
    line-height: 24px;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
}

.table-pagination .table-show span {
    letter-spacing: 1px;
    font-weight: bold;
    padding-right: 6px;
}

.table-pagination .table-show ul {
    display: flex;
    padding: 0;
    margin-bottom: 0;
}

.table-pagination .table-show ul li.page-item {
    list-style-type: none;
    margin-left: 6px;
    margin-right: 6px;
    width: 28px;
    height: 28px;
}

.table-pagination .table-show ul li.page-item:focus,
.table-pagination .table-show ul li.page-item:hover {
    color: #000;
    cursor: pointer;
}

.table-pagination .table-show ul li.page-item.active {
    border: 1px solid #ff9871;
    border-radius: 9999px;
}

@media screen and (max-width: 1000px) {
    .table-pagination .table-show {
        position: inherit;
        justify-content: center;
    }
}

@media screen and (max-width: 636px) {
    .table div{
        font-size: 11px !important;
        padding: inherit !important;
    }
}

.tab-button-group {
  display: block;
  position: relative;
}

.tab-button-group .button:not(:first-child):not(:last-child) {
  border-radius: 0px !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.tab-button-group .button:first-child {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  margin-right: 0px !important;
}

.tab-button-group .button:last-child {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  margin-left: 0px !important;
}

.react-date-picker {
  min-width: 180px;
  height: 45px;
  color: black;
  position: relative;
}

.react-date-picker .react-date-picker__wrapper {
  width: 100%;
  transition: border-radius 138ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 138ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background: transparent;
  border-radius: 8px;
  border: 1px solid lightblue;
  box-sizing: content-box;
}


.react-date-picker .react-date-picker__inputGroup .react-date-picker__inputGroup__input {
  color: rgba(0,0,0,.4);
  padding-left: 9px;
  padding-right: 9px;
  box-shadow: none;
}

.react-date-picker .react-date-picker__inputGroup .react-date-picker__inputGroup__input:invalid {
  background-color: transparent;
}

.react-date-picker .react-date-picker__inputGroup .react-date-picker__inputGroup__input__year {
    flex: 1 1;
}

.react-date-picker .react-date-picker__calendar-button {
  width: 42px;
  background: #00000033;
  color: #fff;
  /*border-top-right-radius: 18px;*/
  /*border-bottom-right-radius: 18px;*/
}

.react-date-picker.react-date-picker--open .react-date-picker__calendar-button {
  border-bottom-right-radius: 0px;
}

.react-date-picker .react-date-picker__calendar-button > svg {
  margin: 0 auto;
}

.react-date-picker .react-date-picker__calendar {
  z-index: 99;
  box-shadow: 0px 4px 4px -2px rgba(0, 0, 0, 0.2);
  width: 100%;
  min-width: 280px;
}

.react-date-picker .react-date-picker__calendar .react-calendar {
  border: none;
  width: 100%;
}

.react-date-picker .react-date-picker__calendar .react-calendar__month-view__weekdays {
    color: #888888;
}


.g-main-detail-history {
    padding-bottom: 42px;
}

.g-main-detail-history .table .table-body .table-cell > div {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
}

.bold-size {
    font-weight: bold;
    font-size: 20px;
    font-family: Lato, sans-serif, Libre Baskerville;
}

.light-size {
    opacity: 80%;
    font-family: Lato, sans-serif, Libre Baskerville;
}

.mother-box {
    background-color: #f2f2f2;
}

.my-button {
    background: transparent;
    border: none;
}

.my-button:hover {
    opacity: 90%;
}


@media screen and (max-width: 556px) {
    .bold-size {
        font-size: 15px;
    }

    .res-container {
        flex-direction: column;
    }
}

@media screen and (max-width: 1000px) {

    .history-filter-group {
        grid-gap: 2rem;
        gap: 2rem
    }

    .history-filter-group .react-date-picker {
        flex: 1 1 !important;
    }

    /*.history-filter-group .col-lg-6.col-sm-12 {*/
    /*    display: flex !important;*/
    /*    align-items: center !important;*/
    /*}*/
}

.FaithmoAuth__wrapper {
    max-width: 1024px;
    min-width: 320px;
    width: 100%;
    margin: 30px auto 30px auto;
    position: relative;
    background-color: white;
    padding: 0px;
    border-radius: 32px;
    border: 0px;
    box-shadow: 0px 4px 8px 4px rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    /* overflow: hidden; */
}

.FaithmoAuth__left {
    width: 320px;
    padding: 32px 24px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    background-color: #9bb5cc;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    overflow: hidden;
}

.title {
    text-align: center;
    text-transform: uppercase;
    color: #fff;
}

.FaithmoAuth__logo {
    max-width: 144px;
    padding: 12px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.FaithmoAuth__content {
    color: white;
    font-size: 18px;
    flex: 1 1;
}

.FaithmoAuth__badges {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}


.FaithmoAuth__badge {
    margin: 8px;
    height: 48px;
    object-fit: cover;
    width: 160px;
    border-radius: 1.1rem;
}

.FaithmoAuth__bottom {
    /*padding-top: 20px;*/
    padding: 10px 5vw;
    /* transform: translateY(100%); */
    color: #204b70;
    font-size: 1.2rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: black;
}

.FaithmoAuth__bottom__left {
    display: flex;
    align-items: center;
}

.FaithmoAuth__terms {
    color: #fff;
    margin-left: 1vw;
}
.FaithmoAuth__copyright {
    color: #fff;
}

[class*='Toast__toast_'] {
    max-width: 1024px;
    min-width: 320px;
    width: 100%;
    margin: 4px auto;
    padding: 10px;
    background-color: steelblue;
    border-radius: 8px;
    border: 0px;
    box-shadow: 1px 1px 2px 0px lightgrey;
    position: relative;
}

[class*='Toast__toast_'] [class*='Toast__toastClose'] {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    background-image: url(/static/media/close-x.edeef416.png);
    background-size: contain;
}

.FaithmoAuth__right {
    flex: 1 1;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: hidden;
}

[class*='Form__formContainer'] {
    position: relative;
    background-color: white;
    padding: 64px 48px;
    border: 0px;
    box-shadow: none;
    margin: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
}

[class*='Form__formContainer'] .close-button-container {
    position: absolute;
    display: block;
    right: 40px;
    top: 40px;
}

[class*='Form__formContainer'] .close-button-container > .close-button {
    position: relative;
    display: block;
    width: 32px;
    height: 32px;
    cursor: pointer;
}

[class*='Form__formContainer'] > [class*='Form__formSection'] {
    flex: 1 1;
    width: auto;
    height: auto;
    transition: all 0.5s;
    overflow: hidden;
}

[class*='Form__formContainer'] > [class*='Form__formSection'] [class*='Anchor__a'] {
    cursor: pointer;
    color: cadetblue;
}

[class*='Form__formContainer'] > [class*='Form__formSection'] [class*='Anchor__a']:hover {
    color: lightblue;
}

[class*='Form__formContainer'] > [class*='Form__formSection'] [class*='Section__sectionBody'] {
    position: relative;
    min-height: 80px;
    grid-gap: 2rem;
    gap: 2rem;
}

[class*='Form__formContainer']
> [class*='Form__formSection']
[class*='Section__sectionBody']
[class*='Form__formField'] {
    margin-bottom: 20px;
}

[class*='Form__formContainer']
> [class*='Form__formSection']
[class*='Section__sectionBody']
[class*='Form__formField']
[class*='Input__input'] {
    width: 100%;
}

[class*='Form__formContainer'] > [class*='Form__formSection'] [class*='Section__sectionHeader'] {
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

[class*='Form__formContainer'] > [class*='Form__formSection'] [class*='Section__sectionHeader'] > img {
    width: 38px;
    height: auto;
}

[class*='Form__formContainer']
> [class*='Form__formSection']
[class*='Section__sectionHeader']
> [class*='Section__sectionHeaderContent'] {
    font-size: 1.75rem;
}

[class*='Form__formContainer'] > [class*='Form__formSection'] [class*='Section__sectionFooter_'] {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

[class*='Form__formContainer'] > [class*='Form__formSection'] [class*='Section__sectionFooter_'] button {
    background: #3FB033;
    border: none;
    height: 48px;
    width: 180px;
    font-size: 17px;
    text-transform: uppercase;
    letter-spacing: 3px;
    border-radius: 1em;
    cursor: pointer;
    color: white;
    font-weight: bold;
}

[class*='Form__formContainer']
> [class*='Form__formSection']
[class*='Section__sectionFooter_']
[class*='Section__sectionFooterPrimaryContent'] {
    margin-top: 20px;
}

[class*='Form__formContainer']
> [class*='Form__formSection']
[class*='Section__sectionFooter_']
[class*='Section__sectionFooterSecondaryContent'] {
    margin-top: 20px;
}

.Form__buttonsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-left: 2px solid gray;
    padding: 0px 0px 0px 48px;
    margin: 0px 0px 0px 48px;
}

.Form__buttonsSubContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 40px;
}

input#email, input#password {
    padding: .7rem 0;
}

@media screen and (max-width: 867px) {
    [class*='Form__formContainer'] {
        flex-direction: column;
    }

    #history-date-filter-group {
        grid-gap: 1rem;
        gap: 1rem;
    }

    .Form__buttonsContainer {
        border-left: none;
        border-top: 2px solid gray;
        padding: 12px 0px 0px 0px;
        margin: 12px 0px 0px 0px;
    }

    [class*='Form__formContainer'] {
        padding: 48px 32px;
    }

    /*[class*='Form__formContainer']*/
    /*> [class*='Form__formSection']*/
    /*[class*='Section__sectionHeader']*/
    /*> [class*='Section__sectionHeaderContent'] {*/
    /*    margin: 0;*/
    /*}*/
    /*[class*='Form__formContainer'] > [class*='Form__formSection'] [class*='Section__sectionHeader'] {*/
    /*    margin-bottom: 0;*/
    /*}*/
}
@media screen and (max-width: 776px) {

    .Form__buttonsSubContainer {
        flex-direction: row;
        width: 100%;
    }
}

@media screen and (max-width: 678px) {
    .FaithmoAuth__left {
        width: auto;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    .FaithmoAuth__right {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
    }

    .FaithmoAuth__badges {
        flex-direction: row;
    }

    .FaithmoAuth__wrapper {
        flex-direction: column;
    }

    .FaithmoAuth__bottom {
        font-size: 0.7rem;
    }
    [class*='Form__formContainer'] {
        padding: 24px;
    }
}

.button.btn-activate {
    padding: 10px 30px;
    width: auto;
    height: auto;
}

.giving.card > .card-body {
    padding: 3rem;
}

.giving.card .giving-list {
    list-style: none;
}

.giving.card .payment-form p.title {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 0.4rem;
}

.giving.card .payment-form .securityCode {
    line-height: 50px;
    text-decoration: underline;
    letter-spacing: 1px;
    color: #fe9169;
}

.MuiInputAdornment-positionStart{
    margin-right: 0 !important;
}

.MuiInputAdornment-positionStart > .MuiTypography-root.MuiTypography-body1.MuiTypography-colorTextSecondary {
    left: 5px !important;

}

@media screen and (max-width: 768px) {
    .giving.card > .card-body {
        padding: 1.5rem;
    }
}

@media screen and (max-width: 600px) {
    .margin-btm-sm {
        margin-bottom: 3rem !important;
    }
}

@media screen and (max-width: 480px) {
    .giving.card .payment-form p.title {
        font-size: 16px;
    }
}


.action-group.membership-action p {
    margin-top: 50px;
    margin-bottom: 0;
}

.action-group.membership-action span {
    font-size: 14px;
    color: #fe9168;
    text-transform: uppercase;
    text-decoration: underline;
    letter-spacing: 1px;
    margin-bottom: 0;
    cursor: pointer;
}

#giving-container {
    width: 80%;
}

/* Membership Edit */
.membership-edit .form-control {
    height: 50px !important;
}

.membership-edit .card-header .title p {
    margin-left: 48px;
}

.membership-edit .card-body {
    padding: 52px 80px;
    min-height: 240px;
    position: relative;
}

.membership-edit .card-body .card-block {
    padding: 32px 0 0 0;
}

.membership-edit .card-choose ul {
    list-style: none;
}

.membership-edit .card-choose ul li p {
    margin-bottom: 0;
}

.membership-edit .card-choose ul li {
    border-top: 2px solid #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
}

.membership-edit .card-choose ul li:last-child {
    padding-top: 32px;
    border-bottom: 2px solid #f2f2f2;
}

.membership-edit .card-choose li label {
    font-weight: bold;
    text-transform: uppercase;
}

.membership-edit .card-detail {
    margin-top: 40px;
}

.membership-edit .card-detail .btn-group .btn {
    height: 50px;
    font-style: italic;
    color: #3d3d3d;
    border-radius: 0;
}

.membership-edit .securityCode {
    line-height: 50px;
    text-decoration: underline;
    letter-spacing: 1px;
    color: #fe9169;
}

/* -- radio Button style */
[type='radio']:checked,
[type='radio']:not(:checked) {
    position: absolute;
    left: -9999px;
}

[type='radio']:checked + label,
[type='radio']:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #3d3d3d;
}

[type='radio']:checked + label:before,
[type='radio']:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 22px;
    height: 22px;
    border: 2px solid #c2c2c2;
    border-radius: 100%;
    background: #fff;
}

[type='radio']:checked + label:after,
[type='radio']:not(:checked) + label:after {
    content: '';
    width: 8px;
    height: 8px;
    background: #fe8462;
    position: absolute;
    top: 7px;
    left: 7px;
    border-radius: 100%;
    transition: all 0.2s ease;
}

[type='radio']:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
}

[type='radio']:checked + label:after {
    opacity: 1;
    transform: scale(1);
}

@media screen and (max-width: 768px) {
    /* MemberShip Edit */
    .membership-edit #first_name {
        margin-bottom: 16px;
    }


}

@media screen and (max-width: 1150px) {
    #total-paper {
        display: none !important;
    }
}

@media screen and (max-width: 700px) {
    .membership-edit .card-body {
        padding: 52px 40px;
    }
}

@media screen and (max-width: 598px) {
    .membership-edit .card-choose ul li:first-child {
        display: block;
        height: auto;
        line-height: 54px;
    }

    #giving-container {
        width: 100%;
    }

    #giving-grid {
        padding: 1rem !important;
    }

    .membership-edit .card-choose ul li:first-child p {
        text-align: center;
        margin-bottom: 0;
    }
}

@media screen and (max-width: 480px) {
    .membership-edit .card-body {
        padding: 52px 20px;
    }

    .membership-edit .form-group label,
    .membership-edit p.title {
        font-size: 16px;
    }
}

.up-detail {
    background-color: #fff;
    border-radius: 30px;
    margin-top: 64px;
    margin-bottom: 32px;
}

.up-detail .item-label {
    float: right;
}

.up-detail .back {
    top: 30px;
    left: 32px;
}

.up-detail .back {
    color: #31c5c3;
}

.up-detail .unsubscribe {
    position: absolute;
    right: 32px;
    top: 29px;
    text-transform: uppercase;
    border-bottom: 1px solid #31c5c3;
    color: #31c5c3;
    letter-spacing: 1px;
    font-weight: bold;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none; /* Likely future */
}

.up-detail .unsubscribe p {
    margin-bottom: 0;
}

.up-detail .avatar {
    text-align: center;
}

.up-detail .avatar .member-status-detail {
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 46px;
}

.up-detail .avatar .check {
    width: 24px;
    vertical-align: text-bottom;
}

.up-detail .up-prop {
    padding-bottom: 40px;
    border-bottom: 1px dashed #afafaf;
}

.up-detail .up-prop .up-prop-left {
    padding: 0 10%;
}

.up-detail .honored_member,
.up-detail .up-notes {
    padding: 42px 53px;
    display: flex;
    flex-wrap: inherit;
    border-bottom: 1px dashed #afafaf;
}

.up-detail .honored_member_detail {
    margin-left: 16px;
}

.up-detail .honored_member_detail p {
    margin-bottom: 0;
}

.up-detail .honored_member_detail .hm-title {
    font-weight: bold;
    text-transform: uppercase;
}

.up-detail .honored_member_detail .hm-detail,
.up-detail .up-notes .notes-detail {
    font-size: 14px;
    padding-top: 4px;
    color: #a4a4a4;
}

.up-detail .up-notes {
    padding: 42px 53px;
    display: block;
    border-bottom: 1px dashed #afafaf;
}

.up-detail .up-notes p {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0px;
}

.up-detail .up-editprofilebtn {
    text-align: center;
    padding: 46px 0;
}

.up-detail .up-editprofilebtn .button.neutral {
    padding: 14px 49.6px;
}

.up-detail .up-groups {
    display: block;
    margin-bottom: 36px;
}

.up-detail .up-groups .card-block,
.up-detail .up-bottomCard .card .card-block {
    padding: 0px 0.25rem;
    min-height: 120px;
}

.up-detail .up-groups .up-groups-detail {
    padding: 32px 48px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.up-detail .up-groups .up-groups-detail .groups-item {
    width: 33%;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.up-detail .up-groups .up-groups-detail .member-detail {
    display: block;
    margin-left: 18px;
}

.up-detail .up-groups .up-groups-detail .member-detail > p {
    font-size: 14px;
    font-style: italic;
    margin-bottom: 4px;
    text-align: left;
}

.up-detail .up-groups .up-groups-detail .member-detail > p.name {
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
}

.up-detail .up-groups .swiper-pagination {
    bottom: 6px;
}

.up-detail .up-bottomCard {
    align-items: stretch;
    justify-content: space-around;
}

.up-detail .up-bottomCard .up-gifts,
.up-detail .up-bottomCard .up-prayer {
    padding: 15px;
}

.up-detail .up-bottomCard .card {
    height: 100%;
}

.up-detail .up-bottomCard .card .card-block .swiper-slide {
    display: flex;
    align-items: center;
}

.up-detail .up-bottomCard .up-giftsInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px 0;
    width: 78%;
    margin: 0 auto;
}

.up-detail .up-bottomCard .up-giftsInfo .left-pane {
    margin-bottom: 0;
    font-size: 18px;
    font-weight: bold;
    text-transform: capitalize;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.up-detail .up-bottomCard .up-giftsInfo .left-pane p {
    margin-bottom: 0;
}

.up-detail .up-bottomCard .up-giftsInfo .left-pane p.up-giftsDate {
    font-weight: normal;
    font-size: 16px;
}

.up-detail .up-bottomCard .up-giftsInfo .price {
    margin-left: auto;
}

.up-detail .up-bottomCard .up-prayerItem {
    padding: 32px 48px;
    margin: 0;
}

.up-detail .up-bottomCard .up-prayerItem a {
    color: inherit;
}

.up-detail .up-bottomCard .up-prayerItem p {
    margin-bottom: 0;
}

.up-detail .up-bottomCard .up-prayerItem .up-Pdetail {
    font-size: 14px;
}

.up-detail .upe-prop .upe-prop-left .form-group {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
}

.up-detail .upe-prop .upe-prop-right {
    /*margin-top: auto;*/
}

.up-detail .upe-prop .upe-prop-right label {
    font-weight: bold;
}

.up-detail .upe-prop .upe-prop-right .upe-state {
    width: 100%;
    margin-bottom: 8px;
}

.up-detail .upe-prop .upe-prop-right .upe-state .dropdown-item {
    margin-bottom: 0;
}

.up-detail .upe-prop .upe-prop-right .upe-stateBtn {
    width: 100%;
    text-align: left;
    font-size: 14px;
    height: 34px;
    border-radius: 0;
    padding-left: 12px;
    color: #464a4c;
    font-size: 1rem;
}

.up-detail .upe-prop .upe-prop-right .upe-stateBtn::after {
    display: none;
}

.up-detail .upe-prop .upe-prop-right .upe-stateBtn .entypo-down-open-big::before {
    top: 4px;
}

.up-detail .upe-prop-bottom {
    margin-top: 60px;
    padding-left: 71.797px;
}

.up-detail .upe-b-member {
    margin-top: 40px;
    margin-bottom: 40px;
}

.up-detail .upe-b-member .upe-b-ques {
    display: inline-flex;
    float: left;
}

.up-detail .upe-b-member .upe-b-ques p {
    padding-right: 8px;
    font-weight: bold;
    margin-bottom: 0;
    text-transform: uppercase;
}

.up-detail .upe-b-member .upe-b-radio-group {
    display: flex;
    margin-left: auto;
    max-width: 360px;
}

.up-detail .upe-b-member .upe-b-joined-date {
    margin-left: auto;
    max-width: 340px;
}

.up-detail .upe-b-radio-group .upe-b-radio-btn {
    margin: 0 20px;
}

.up-detail .upe-b-radio-group label {
    text-transform: uppercase;
}

.up-detail .upe-b-radio-group [type='radio']:checked + label:before,
.up-detail .upe-b-radio-group [type='radio']:not(:checked) + label:before {
    border: 2px solid #ff9f78;
}

.up-detail .upe-b-radio-group [type='radio']:checked + label:after,
.up-detail .upe-b-radio-group [type='radio']:not(:checked) + label:after {
    width: 12px;
    height: 12px;
    top: 5px;
    left: 5px;
}

.up-detail .upe-b-member .upe-b-notes {
    margin-top: 28px;
}

.up-detail .upe-b-member .upe-b-notes .form-control {
    margin: 0 15px;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.15);
}

.up-detail .upe-b-savebtn {
    text-align: center;
}

.unsubscribe {
    display: none;
}

.address-control {
    margin-top: 15px;
}

input[type="text"], input[type="email"] {
    padding: 20px
}

@media screen and (max-width: 1400px) {
    .up-detail {
        margin-left: 12px !important;
        margin-right: 12px !important;
    }
}

@media screen and (max-width: 1280px) {
    .up-detail .up-prop .up-prop-left {
        padding: 0 0 0 5%;
    }
}

@media screen and (max-width: 991px) {
    .up-detail .up-prop {
        margin-left: 0;
        margin-right: 0;
    }

    .up-detail .up-prop .up-prop-left,
    .up-detail .up-prop .up-prop-right {
        padding: 0;
    }

    .up-detail .honored_member {
        padding-left: 0;
    }

    .up-detail .upe-prop .upe-prop-left,
    .up-detail .upe-prop .upe-prop-right {
        padding-left: 0;
    }

    .up-detail .upe-prop .upe-prop-left {
        margin-bottom: 10px;
    }

    .upe-prop {
        margin: 0;
    }

    .up-detail .upe-prop-bottom {
        padding-left: 0;
    }

    .up-detail .up-groups .up-groups-detail {
        display: block;
    }

    .up-detail .up-groups .up-groups-detail .groups-item {
        width: 100%;
    }
}

@media screen and (max-width: 800px) {
    .up-detail .upe-b-radio-group .upe-b-radio-btn {
        margin: 0px 10px;
    }

    .up-prop-right .homephone .Phone p {
        line-height: 40px;
    }
}

@media screen and (max-width: 768px) {
    .up-detail .honored_member {
        display: block;
        padding-right: 0;
    }

    .honored_member_logo {
        text-align: center;
        margin-bottom: 10px;
    }

    .up-detail .honored_member_detail {
        margin-left: 0;
    }

    .up-detail .up-notes {
        padding: 42px 0;
    }

    .up-detail .honored_member,
    .up-detail .up-notes {
        margin: 0;
        text-align: center;
    }

    .up-detail .upe-b-member {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .up-detail .upe-b-member .upe-b-ques {
        float: none;
        margin-bottom: 20px;
    }

    /* .up-detail .upe-b-member .upe-b-radio-group {
      float: none;
    } */
}

@media screen and (max-width: 640px) {
    .up-detail .back button,
    .up-detail .unsubscribe p {
        font-size: 14px;
    }

    .up-detail .upe-b-member .upe-b-ques p {
        font-size: 15px;
    }

    .up-detail .unsubscribe {
        top: 35px;
        font-weight: inherit;
    }

    .up-detail {
        padding: 16px;
    }
}

@media screen and (max-width: 599px) {
    .up-detail .item-label {
        float: left;
    }
}

@media screen and (max-width: 430px) {
    .up-detail .unsubscribe {
        right: 10px;
        letter-spacing: 0;
    }

    .up-detail .back {
        left: 20px;
        letter-spacing: 0;
    }

    .up-detail .up-editprofilebtn .button.neutral {
        padding: 10px 22px;
        font-size: 14px;
    }

    .up-detail .upe-b-savebtn .button {
        padding: 12px 18px;
        font-size: 14px;
    }

    .up-detail .up-bottomCard .up-giftsInfo {
        display: block;
        text-align: center;
    }
}

@font-face {
    font-family: 'icomoon';
    src: url(/static/media/icomoon.f1c2dae0.eot);
    src: url(/static/media/icomoon.f1c2dae0.eot#iefix) format('embedded-opentype'),
    url(/static/media/icomoon.4267c8ce.ttf) format('truetype'),
    url(/static/media/icomoon.d9b31276.woff) format('woff'),
    url(/static/media/icomoon.5a722b61.svg#icomoon) format('svg');
    font-weight: normal;
    font-style: normal;
}

* {
    font-family: Lato, sans-serif, Libre Baskerville;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: subpixel-antialiased;
    -webkit-text-stroke: 1px transparent;
    box-sizing: border-box;
}

:root {
    --swiper-theme-color: #fe9168;
    --color_0: rgb(255, 255, 255);
    --color_27: rgb(233, 159, 134);
    --color_1: rgb(255, 255, 255);
    --color_2: rgb(0, 0, 0);
    --color_3: rgb(237, 28, 36);
    --color_4: rgb(0, 136, 203);

    --color_5: rgb(255, 203, 5);
    --color_6: rgb(114, 114, 114);
    --color_7: rgb(176, 176, 176);
    --color_9: rgb(114, 114, 114);
    --color_10: rgb(176, 176, 176);
    --color_12: rgb(204, 204, 204);
    --color_13: rgb(160, 160, 159);
    --color_14: rgb(96, 94, 94);
    --color_15: rgb(47, 46, 46);
    --color_16: rgb(186, 233, 255);
    --color_17: rgb(151, 222, 255);
    --color_18: rgb(48, 189, 255);
    --color_19: rgb(32, 126, 169);
    --color_20: rgb(16, 63, 84);
    --color_21: rgb(182, 232, 227);
    --color_22: rgb(141, 209, 202);
    --color_23: rgb(65, 186, 174);
    --color_28: rgb(222, 80, 33);
    --color_29: rgb(148, 54, 22);
    --color_30: rgb(74, 27, 11);
    --color_31: rgb(244, 234, 177);
    --color_32: rgb(233, 219, 137);
    --color_24: rgb(43, 124, 116);
    --color_33: rgb(222, 195, 40);
    --color_34: rgb(148, 130, 27);
    --color_35: rgb(74, 65, 13);
}

html {
    width: unset !important;
    position: relative !important;
    overflow: unset !important;
    height: 100%;
}

body {
    position: relative;
    /* background-image: url("assets/images/staticBg.jpg");
    overflow-x: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; */
    background-color: rgb(178, 197, 214);
}

#root {
    height: 100vh;
    /* overflow-y: auto; */
}

.app-loading-container {
    background: linear-gradient(180deg, #fafafa 0, #fefefe 0, #ffffff 51%, #fafafa);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999999;
}

.app-content {
    position: relative;
    padding-top: 20px;
    padding-bottom: 40px;
    flex-grow: 1;
    width: 100%;
}

.app-header-spacer {
    /*height: 100px;*/
    height: -webkit-max-content;
    height: max-content;
    width: 100%;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    background: transparent;
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-thumb {
    border-radius: 99px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

textarea:disabled {
    resize: none;
}

body {
    margin: 0;
    padding: 0;
}

svg {
    display: inline-block;
    width: 1em;
    height: 1em;
    overflow: visible;
    font-size: inherit;
}

a,
a:hover {
    color: inherit;
}

a.link {
    cursor: pointer;
    color: #0275d8;
    text-decoration: none;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: top;
}

button.link {
    cursor: pointer;
    color: #fe9168;
    text-decoration: underline;
    text-transform: uppercase;
    background-color: transparent;
    border: none;
    box-shadow: none !important;
    font-weight: bold;
}

.form-control:focus {
    box-shadow: 0 0 0 1px rgba(0, 123, 255, 0.25);
}

.color-red {
    color: #fe9168 !important;
}

.color-danger {
    color: #f44336 !important;
}

.color-lightblue {
    color: #31c5c3 !important;
}

.red-large-text {
    font-size: 22px;
    color: #fe9168;
    line-height: 38px;
}

.form-control::placeholder {
    font-style: italic;
}

a:hover {
    text-decoration: inherit;
    cursor: pointer;
}

:focus {
    outline: none !important;
}

.button:focus {
    box-shadow: inset 2px 2px 8px 0px rgb(0 0 0 / 20%) !important;
}

.MuiSelect-select.MuiSelect-select:focus {
    border-radius: 4px;
}

.customWidth {
    max-width: 1400px;
    margin-right: auto;
    margin-left: auto;
}

.back {
    position: absolute;
    top: 8px;
    left: 54px;
    font-size: 18px;
    color: white;
    font-weight: bold;
}

.back > svg {
    font-size: inherit;
    color: inherit;
    font-weight: inherit;
}

.back a,
.back button {
    box-shadow: none !important;
    outline: none !important;
    color: inherit;
    text-transform: uppercase;
    font-weight: inherit;
    font-size: 14px;
    letter-spacing: 2px;
    background: transparent;
    border: none;
    border-bottom: 1px solid white;
    border-bottom-color: inherit;
    padding: 0;
    text-decoration: none;
}

.giving-history {
    padding-top: 64px !important;
}
.card-wrapper {
    position: relative;
    padding: 46px 52px;
}

.card {
    border-radius: 10px;
    border: 0;
    box-shadow: 1px 1px 8px 0 #858585;
    overflow: visible;
}

.card.transparent-card {
    box-shadow: none;
    background: transparent;
}

.card.border-card {
    border: 2px solid #f2f2f2;
}

.card > .card-header {
    position: relative;
    display: flex;
    align-items: stretch;
    padding: 0;
    background: #ededed;
    border-bottom: 2px solid #f2f2f2;
    flex-wrap: wrap;
}

.card.transparent-card > .card-header {
    background: inherit;
}

.card > .card-header > .title-buttons {
    padding: 0px 24px;
    display: flex;
    align-items: center;
}

.card > .card-header > .title-buttons > .button {
    margin: 12px;
}

.card > .card-header > .title {
    flex: 1 1;
    display: flex;
    align-items: center;
    padding: 0.907rem 1.25rem;
    vertical-align: bottom;
    min-width: 320px;
    min-height: 0px;
}

.card > .card-header > .title.center {
    justify-content: center;
}

.card > .card-header > .title > img {
    width: 32px;
    height: 32px;
    object-fit: contain;
}

.card > .card-header p {
    display: inline-block;
    text-transform: uppercase;
    margin-bottom: 0;
    font-weight: bold;
    margin-left: 8px;
    letter-spacing: 1px;
    vertical-align: text-bottom;
}

.card > .card-block {
    position: relative;
    padding: 1.25rem;
    overflow: auto;
}

.section-heading {
    font-size: 22px;
    color: #fe9168;
    line-height: 38px;
    margin-bottom: 24px;
}

/* Tooltip */
svg.icon-info {
    border: 2px solid #fe9168;
    color: #fe9168;
    box-sizing: content-box;
    width: 12px !important;
    height: 12px !important;
    padding: 4px;
    border-radius: 999px;
    cursor: pointer;
}

/* Buttons */
.button {
    /* text-transform: capitalize; */
    font-size: 15px;
    font-weight: 1000;
    letter-spacing: 1px;
    border-radius: 2em;
    cursor: pointer;
    color: #fff;
    width: auto;
    height: auto;
    text-align: center;
    box-sizing: border-box;
    display: inline-block;
    -webkit-user-select: none;
            user-select: none;
}

input[type=number] {
    -moz-appearance: textfield;
    /*appearance: textfield;*/
    margin: 0;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.button:disabled {
    cursor: not-allowed !important;
}

.button.right-top {
    position: absolute;
    right: 16px;
    top: 16px;
}

.button.icon-button {
    height: 15px;
    width: 15px;
    font-size: 20px;
    /* line-height: 42px; */
    margin-left: 1vw;
    margin-right: 6vw;
    border-radius: 24px;
    align-items: center;
    justify-content: center;
}

.button.small {
    font-size: 12px;
    letter-spacing: 1px;
    padding: 0px 16px;
    min-width: 90px;
    min-height: 38px;
}

.spanBtn {
    color:rgb(0, 0, 0);
    font-weight: 500;
    text-align: center;
    font-size: 16px;
    min-width: 200px;
    max-width: 200px;
    margin-top: 10px;
}

.button.medium {
    font-size: 14px;
    padding: 0px 24px;
    letter-spacing: 2px;
    min-width: 200px;
    max-width: 200px;
    min-height: 40px;
}

.button.large {
    font-size: 20px;
    padding: 0px 48px;
    letter-spacing: 2px;
    min-width: 178px;
    min-height: 64px;
}

.button.round {
    border-radius: 80px;
}

.button.darkgreen {
    background-color: #4bba42;
    color: #fff;
}

.button.black {
    background-color: #323232;
    color: #fff;
    border: none;
}

.button.lightgreen {
    background-color: #4bba42;
    color: #fff;
    border: none;
    border-radius: 2em;
}

.button.red {
    background-color: #ff5c5c;
    color: #fff;
    border: none;
}

.button.gray {
    background-color: #777;
    color: #fff;
    border: none;
}

.button.gradient {
    background: linear-gradient(180deg, #feaf78 0, #fe8563 0, #fc8362 0, #feaf78 0, #f57754);
    border: none;
}

.button.gradient:disabled {
    color: #d9d9d9;
    background: linear-gradient(180deg, #dbdbdb 0, #e6e4e3 0, #d2cbca 0, #bfb8b4 0, #d7d7d7);
    cursor: not-allowed;
}

.button.neutral {
    background-color: rgba(0, 0, 0, .3);
    border: none;
    color: rgba(255, 255, 255, .8);
    border-radius: 2em;
    /* font-size: small; */
}

.MuiInputAdornment-root.MuiInputAdornment-positionStart {
    position: relative !important;
}

.MuiTypography-root.MuiTypography-body1.MuiTypography-colorTextSecondary {
    position: absolute !important;
    left: 13px;
}

.button.darkblue {
    background-color: rgb(32, 126, 169);
    border: none;
    color: white !important;
}

.button.neutral:disabled {
    background-color: #00000020;
    border: 3px solid #00000020;
    color: #00000020;
}

.button.border--red {
    border-color: #fe9168;
    color: #fe9168;
}

.button.border {
    border-color: black;
    color: black;
}

label + .MuiInput-formControl {
    margin-top: 0 !important;
}

.button.border--gray {
    border-color: #d7d7d7;
    color: #878787;
}

.action-group {
    text-align: center;
    margin-top: 42px;
}

div[class*="Section__container___"] {
    position: unset !important;
}

.action-group button {
    text-transform: uppercase;
    border-radius: 4em;
    cursor: pointer;
    color: #fff;
    border: none;
    width: 178px;
    height: 38px;
    font-size: 14px;
    letter-spacing: 2px;
    margin: 9px;
}

.action-group .btn-save {
    background: linear-gradient(180deg, #feaf78 0, #fe8563 0, #fc8362 0, #feaf78 0, #f57754);
}

.action-group .btn-cancel {
    background-color: transparent;
    border: 3px solid #fe9168;
    color: #fe9168;
}

.swiper-container {
    width: 100%;
    height: 100%;
}

.form-group span.helper-text {
    display: block;
    position: relative;
    bottom: -5px;
    font-size: 14px;
    line-height: 12px;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    text-align: left;
}

span.helper-text.error {
    color: rgb(244, 67, 54);
}

.form-label {
    font-size: 16px;
    font-weight: bold;
    line-height: 32px;
    margin-top: 0.5rem;
    padding-right: 15px;
    padding-left: 15px;
    overflow: visible;
    white-space: nowrap;
}

.item-label {
    font-weight: bold;
    margin-bottom: 20px;
}

.item-value {
    margin-bottom: 20px;
}

.name {
    white-space: break-spaces;
    word-break: break-word;
    text-align: center;
}

@media screen and (max-width: 960px) {
    .card > .card-header.lg-column {
        flex-direction: column;
    }
}

@media screen and (max-width: 776px) {
    .card > .card-header {
        flex-direction: column;
        align-items: stretch;
    }

    .card > .card-header > .title-buttons {
        padding: 0 16.5px;
        margin-bottom: 10px;
        flex-direction: column;
        align-items: stretch;
    }

    .card > .card-header > .title-buttons .button {
        flex: 1 1;
    }

    .button.large {
        padding: 0px 48px;
    }

    .button.full {
        min-width: 100%;
    }

    .spanBtn {
        min-width: 100%;
    }
}

@media screen and (max-width: 480px) {
    #root {
        height: 100%;
    }

    .card-wrapper {
        padding: 46px 10px !important;
    }

    .back {
        left: 14px;
    }

    .button.signup {
        padding: 0px 5px;
        letter-spacing: 2px;
        max-width: 100%;
        min-width: 47%;
        max-height: 50px;
    }

    .button.large {
        padding: 0px 28px;
        font-size: 14px;
        letter-spacing: 1px;
    }
}

/* Navbar */

/*.nav-col{*/
/*    flex: 1 1 33%;*/
/*    flex: 0 1 33%;*/
/*}*/

/* Tabs */

.card-tabs {
    border-radius: 30px;
    border: 0;
    box-shadow: 1px 1px 8px 0 #858585;
    position: relative;
    overflow: hidden;
}

.card-tabs .nav-tabs .nav-link {
    flex: 1 1;
    border: none;
    border-radius: 0;
    line-height: 60px;
    padding: 0px;
}

.card-tabs .nav-tabs .nav-link > span {
    line-height: 1.5;
    display: inline-block;
    vertical-align: middle;
}

.card-tabs .nav-tabs .nav-item {
    height: 60px;
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 1px;
    background-color: #ededed;
    color: #3f3f3f;
    border: none;
    border-radius: 0;
    border-right: 2px solid #e0e0e0;
    -webkit-user-select: none;
            user-select: none;
    cursor: pointer;
}

.card-tabs .nav-tabs .nav-item.active {
    background: #c4c4c4;
    color: #fff;
}

.card-tabs .nav-tabs .nav-item:last-child {
    border: none;
}

.card-tabs .tab-content {
    position: relative;
    min-height: 344px;
    background-color: #fff;
}

@media screen and (max-width: 676px) {
    .card-tabs .nav-tabs .nav-item .nav-link {
        font-size: 0.8rem;
    }
}

@media screen and (max-width: 550px) {
    .card-tabs .nav-tabs {
        display: block;
    }

    .card-tabs .nav-tabs .nav-item {
        width: 100%;
    }

    .card-tabs .nav-tabs .nav-item {
        font-size: 1rem;
        border-right: 0;
        border-bottom: 3px solid #e0e0e0;
    }

    .card-tabs .nav-tabs .nav-item:last-child {
        border-top-right-radius: 0;
        border-bottom: none;
    }

    .card-tabs .nav-tabs .nav-item:first-child {
        border-top-right-radius: 30px;
    }
}

/* Table */
.custom-scrollbars .table-header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
    padding: 0px;
}

.custom-scrollbars .table-header > div {
    background-color: white;
}

div.table {
    position: relative;
    background: white;
    box-sizing: border-box;
    border: 2px solid #f2f2f2;
    overflow: hidden;
    width: auto;
}

div.table.rounded {
    border-radius: 30px !important;
}

div.table > .table-header {
    position: relative;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    overflow: hidden;
    -webkit-user-select: none;
            user-select: none;
    cursor: pointer;
}

div.table .table-row {
    display: flex;
    align-items: stretch;
    justify-content: center;
    border-bottom: 2px solid #f2f2f2;
}

div.table .table-row > .table-cell {
    min-height: 60px;
    padding: 8px;
    text-align: center;
    border-right: 2px solid #f2f2f2;
}

div.table .table-row > .table-cell:last-child {
    border-right: none;
}

div.table > .table-header > .table-row > .table-cell {
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
}

.privacy-p {
    margin-left: 3rem;
}

.privacy-terms-modal article {
    padding: 5rem;
}


@media screen and (max-width: 778px) {

    .privacy-terms-modal article {
        padding: 2rem;
    }
}



/* for debugging css layout purpose */


.debug{
    border: 2px solid;
}


.purple-bebug{
    border-color: purple;
}


.coral-bebug{
    border-color: coral;
}
.violet-bebug{
    border-color: violet;
}
