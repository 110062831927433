.up-detail {
    background-color: #fff;
    border-radius: 30px;
    margin-top: 64px;
    margin-bottom: 32px;
}

.up-detail .item-label {
    float: right;
}

.up-detail .back {
    top: 30px;
    left: 32px;
}

.up-detail .back {
    color: #31c5c3;
}

.up-detail .unsubscribe {
    position: absolute;
    right: 32px;
    top: 29px;
    text-transform: uppercase;
    border-bottom: 1px solid #31c5c3;
    color: #31c5c3;
    letter-spacing: 1px;
    font-weight: bold;
    cursor: pointer;
    user-select: none; /* Likely future */
}

.up-detail .unsubscribe p {
    margin-bottom: 0;
}

.up-detail .avatar {
    text-align: center;
}

.up-detail .avatar .member-status-detail {
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 46px;
}

.up-detail .avatar .check {
    width: 24px;
    vertical-align: text-bottom;
}

.up-detail .up-prop {
    padding-bottom: 40px;
    border-bottom: 1px dashed #afafaf;
}

.up-detail .up-prop .up-prop-left {
    padding: 0 10%;
}

.up-detail .honored_member,
.up-detail .up-notes {
    padding: 42px 53px;
    display: flex;
    flex-wrap: inherit;
    border-bottom: 1px dashed #afafaf;
}

.up-detail .honored_member_detail {
    margin-left: 16px;
}

.up-detail .honored_member_detail p {
    margin-bottom: 0;
}

.up-detail .honored_member_detail .hm-title {
    font-weight: bold;
    text-transform: uppercase;
}

.up-detail .honored_member_detail .hm-detail,
.up-detail .up-notes .notes-detail {
    font-size: 14px;
    padding-top: 4px;
    color: #a4a4a4;
}

.up-detail .up-notes {
    padding: 42px 53px;
    display: block;
    border-bottom: 1px dashed #afafaf;
}

.up-detail .up-notes p {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0px;
}

.up-detail .up-editprofilebtn {
    text-align: center;
    padding: 46px 0;
}

.up-detail .up-editprofilebtn .button.neutral {
    padding: 14px 49.6px;
}

.up-detail .up-groups {
    display: block;
    margin-bottom: 36px;
}

.up-detail .up-groups .card-block,
.up-detail .up-bottomCard .card .card-block {
    padding: 0px 0.25rem;
    min-height: 120px;
}

.up-detail .up-groups .up-groups-detail {
    padding: 32px 48px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.up-detail .up-groups .up-groups-detail .groups-item {
    width: 33%;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.up-detail .up-groups .up-groups-detail .member-detail {
    display: block;
    margin-left: 18px;
}

.up-detail .up-groups .up-groups-detail .member-detail > p {
    font-size: 14px;
    font-style: italic;
    margin-bottom: 4px;
    text-align: left;
}

.up-detail .up-groups .up-groups-detail .member-detail > p.name {
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
}

.up-detail .up-groups .swiper-pagination {
    bottom: 6px;
}

.up-detail .up-bottomCard {
    align-items: stretch;
    justify-content: space-around;
}

.up-detail .up-bottomCard .up-gifts,
.up-detail .up-bottomCard .up-prayer {
    padding: 15px;
}

.up-detail .up-bottomCard .card {
    height: 100%;
}

.up-detail .up-bottomCard .card .card-block .swiper-slide {
    display: flex;
    align-items: center;
}

.up-detail .up-bottomCard .up-giftsInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px 0;
    width: 78%;
    margin: 0 auto;
}

.up-detail .up-bottomCard .up-giftsInfo .left-pane {
    margin-bottom: 0;
    font-size: 18px;
    font-weight: bold;
    text-transform: capitalize;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.up-detail .up-bottomCard .up-giftsInfo .left-pane p {
    margin-bottom: 0;
}

.up-detail .up-bottomCard .up-giftsInfo .left-pane p.up-giftsDate {
    font-weight: normal;
    font-size: 16px;
}

.up-detail .up-bottomCard .up-giftsInfo .price {
    margin-left: auto;
}

.up-detail .up-bottomCard .up-prayerItem {
    padding: 32px 48px;
    margin: 0;
}

.up-detail .up-bottomCard .up-prayerItem a {
    color: inherit;
}

.up-detail .up-bottomCard .up-prayerItem p {
    margin-bottom: 0;
}

.up-detail .up-bottomCard .up-prayerItem .up-Pdetail {
    font-size: 14px;
}

.up-detail .upe-prop .upe-prop-left .form-group {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
}

.up-detail .upe-prop .upe-prop-right {
    /*margin-top: auto;*/
}

.up-detail .upe-prop .upe-prop-right label {
    font-weight: bold;
}

.up-detail .upe-prop .upe-prop-right .upe-state {
    width: 100%;
    margin-bottom: 8px;
}

.up-detail .upe-prop .upe-prop-right .upe-state .dropdown-item {
    margin-bottom: 0;
}

.up-detail .upe-prop .upe-prop-right .upe-stateBtn {
    width: 100%;
    text-align: left;
    font-size: 14px;
    height: 34px;
    border-radius: 0;
    padding-left: 12px;
    color: #464a4c;
    font-size: 1rem;
}

.up-detail .upe-prop .upe-prop-right .upe-stateBtn::after {
    display: none;
}

.up-detail .upe-prop .upe-prop-right .upe-stateBtn .entypo-down-open-big::before {
    top: 4px;
}

.up-detail .upe-prop-bottom {
    margin-top: 60px;
    padding-left: 71.797px;
}

.up-detail .upe-b-member {
    margin-top: 40px;
    margin-bottom: 40px;
}

.up-detail .upe-b-member .upe-b-ques {
    display: inline-flex;
    float: left;
}

.up-detail .upe-b-member .upe-b-ques p {
    padding-right: 8px;
    font-weight: bold;
    margin-bottom: 0;
    text-transform: uppercase;
}

.up-detail .upe-b-member .upe-b-radio-group {
    display: flex;
    margin-left: auto;
    max-width: 360px;
}

.up-detail .upe-b-member .upe-b-joined-date {
    margin-left: auto;
    max-width: 340px;
}

.up-detail .upe-b-radio-group .upe-b-radio-btn {
    margin: 0 20px;
}

.up-detail .upe-b-radio-group label {
    text-transform: uppercase;
}

.up-detail .upe-b-radio-group [type='radio']:checked + label:before,
.up-detail .upe-b-radio-group [type='radio']:not(:checked) + label:before {
    border: 2px solid #ff9f78;
}

.up-detail .upe-b-radio-group [type='radio']:checked + label:after,
.up-detail .upe-b-radio-group [type='radio']:not(:checked) + label:after {
    width: 12px;
    height: 12px;
    top: 5px;
    left: 5px;
}

.up-detail .upe-b-member .upe-b-notes {
    margin-top: 28px;
}

.up-detail .upe-b-member .upe-b-notes .form-control {
    margin: 0 15px;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.15);
}

.up-detail .upe-b-savebtn {
    text-align: center;
}

.unsubscribe {
    display: none;
}

.address-control {
    margin-top: 15px;
}

input[type="text"], input[type="email"] {
    padding: 20px
}

@media screen and (max-width: 1400px) {
    .up-detail {
        margin-left: 12px !important;
        margin-right: 12px !important;
    }
}

@media screen and (max-width: 1280px) {
    .up-detail .up-prop .up-prop-left {
        padding: 0 0 0 5%;
    }
}

@media screen and (max-width: 991px) {
    .up-detail .up-prop {
        margin-left: 0;
        margin-right: 0;
    }

    .up-detail .up-prop .up-prop-left,
    .up-detail .up-prop .up-prop-right {
        padding: 0;
    }

    .up-detail .honored_member {
        padding-left: 0;
    }

    .up-detail .upe-prop .upe-prop-left,
    .up-detail .upe-prop .upe-prop-right {
        padding-left: 0;
    }

    .up-detail .upe-prop .upe-prop-left {
        margin-bottom: 10px;
    }

    .upe-prop {
        margin: 0;
    }

    .up-detail .upe-prop-bottom {
        padding-left: 0;
    }

    .up-detail .up-groups .up-groups-detail {
        display: block;
    }

    .up-detail .up-groups .up-groups-detail .groups-item {
        width: 100%;
    }
}

@media screen and (max-width: 800px) {
    .up-detail .upe-b-radio-group .upe-b-radio-btn {
        margin: 0px 10px;
    }

    .up-prop-right .homephone .Phone p {
        line-height: 40px;
    }
}

@media screen and (max-width: 768px) {
    .up-detail .honored_member {
        display: block;
        padding-right: 0;
    }

    .honored_member_logo {
        text-align: center;
        margin-bottom: 10px;
    }

    .up-detail .honored_member_detail {
        margin-left: 0;
    }

    .up-detail .up-notes {
        padding: 42px 0;
    }

    .up-detail .honored_member,
    .up-detail .up-notes {
        margin: 0;
        text-align: center;
    }

    .up-detail .upe-b-member {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .up-detail .upe-b-member .upe-b-ques {
        float: none;
        margin-bottom: 20px;
    }

    /* .up-detail .upe-b-member .upe-b-radio-group {
      float: none;
    } */
}

@media screen and (max-width: 640px) {
    .up-detail .back button,
    .up-detail .unsubscribe p {
        font-size: 14px;
    }

    .up-detail .upe-b-member .upe-b-ques p {
        font-size: 15px;
    }

    .up-detail .unsubscribe {
        top: 35px;
        font-weight: inherit;
    }

    .up-detail {
        padding: 16px;
    }
}

@media screen and (max-width: 599px) {
    .up-detail .item-label {
        float: left;
    }
}

@media screen and (max-width: 430px) {
    .up-detail .unsubscribe {
        right: 10px;
        letter-spacing: 0;
    }

    .up-detail .back {
        left: 20px;
        letter-spacing: 0;
    }

    .up-detail .up-editprofilebtn .button.neutral {
        padding: 10px 22px;
        font-size: 14px;
    }

    .up-detail .upe-b-savebtn .button {
        padding: 12px 18px;
        font-size: 14px;
    }

    .up-detail .up-bottomCard .up-giftsInfo {
        display: block;
        text-align: center;
    }
}
